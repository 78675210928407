import {Injectable} from "@angular/core";
import {CanActivate, Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";

@Injectable({
  providedIn: "root",
})
export class LoginGuard implements CanActivate {
  isAuthenticated= false;
  isNew = false;

  private readonly allowedRoutesForNewUser = [
    '/login/new-user',
    '/terms',
    '/settings',
    '/price-list',
    '/privacy',
  ];

  constructor(
    public authService: AuthService,
    public router: Router,
  ) {
    this.authService.isLoggedIn().subscribe({
      next: (value) => {
        this.isAuthenticated = value;
      },
    });

    this.authService.isNew$.subscribe({
      next: (value) => {
        this.isNew = value;
      },
    });

  }

  canActivate(): boolean {
    const currentUrl = this.router.url;

    // Allow navigation for routes specified in allowedRoutesForNewUser
    if (this.allowedRoutesForNewUser.some((route) => currentUrl.includes(route))) {
      return true;
    }

    // Allow authenticated users and non-new users to continue
    if (!this.isNew) {
      return true;
    }

    // If the user is new, redirect to home
    this.router.navigate(["/"]);
    return false;
  }
}
