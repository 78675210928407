import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import {NewUserComponent} from "src/app/features/login/new-user/new-user.component";
import {ModalService} from "../../services/modal.service";
import {AuthService} from "../../services/auth.service";

@Injectable({
  providedIn: "root",
})
export class LeaveFormGuard implements CanDeactivate<NewUserComponent> {
  isAuthenticated= false;
  isNew = false;

  private readonly allowedRoutes = [
    '/terms',
    '/settings',
    '/privacy',
    '/login/new-user',
    '/price-list',
  ];


  constructor(
    private modalService: ModalService,
    private authService: AuthService,
  ) {
    this.authService.isLoggedIn().subscribe({
      next: (value) => {
        this.isAuthenticated = value;
      },
    });

    this.authService.isNew$.subscribe({
      next: (value) => {
        this.isNew = value;
      },
    });
  }

  canDeactivate(
    component: NewUserComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {

    // Block navigation if nextState unexpectedly doesn't exist
    if (!nextState) {
      return false;
    }

    const { url: nextUrl } = nextState;

    // Allow navigation for allowed routes
    if (this.allowedRoutes.some((route) => nextUrl.includes(route))) {
      return true;
    }

    // Show modal if user is new and not authenticated
    if (!this.isAuthenticated && this.isNew) {
      this.modalService.showAreYouSureModal();
      return false;
    }

    return true;
  }
}
