import { Routes } from "@angular/router";
import { LoginGuard } from "../../../shared/authguards/login.guard";

export const PREMIUM_LANDING_PAGE_ROUTES: Routes = [
  {
    path: "premium-welcome",
    loadComponent: () => import("./premium-landing-page.component").then(m => m.PremiumLandingPageComponent),
    data: { title: "pageTitles.premiumPage" },
    canActivate: [LoginGuard],
    children: [],
  },
];
