import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { PremiumServicesService } from "../../services/premium-services.service";

@Injectable({
  providedIn: "root"
})
export class PremiumGuard implements CanActivate {

  constructor(
    private router: Router,
  ) {}

  canActivate(): boolean {
    const hasPaidService: boolean = JSON.parse(sessionStorage.getItem("HAS_PAID_SERVICE")); //true if institution has paid services
    const hasRedirect: boolean = JSON.parse(sessionStorage.getItem("redirectToPremiumAfterLogin")); //true if coming from advertisement link
    const premiumServiceEnabled: boolean = JSON.parse(sessionStorage.getItem("PREMIUM_SERVICE_ENABLED")); // true if premium service is turned on
    const premiumEnabled: boolean = premiumServiceEnabled && (hasPaidService || hasRedirect);

    if (!premiumEnabled) {
      this.router.navigateByUrl("/"); // Redirect if access is denied
      return false;
    }
    return true;
  }
}
